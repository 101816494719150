import imgProxy from "~/utils/imgproxy";

import type { ImageField } from "~/types/drupal_jsonapi";

import "./CommonFirstScreen.css";

export default function CommonFirstScreen(props: { fieldImage: ImageField }) {
  return (
    <>
      <picture class="full-visual">
        <source
          media="(min-width: 1367px)"
          srcset={imgProxy(
            props.fieldImage.uri.url,
            "size:1920:1080/resizing_type:fill",
          )}
        />
        <source
          media="(min-width: 1025px) and (max-width: 1366px)"
          srcset={imgProxy(
            props.fieldImage.uri.url,
            "size:1366:768/resizing_type:fill",
          )}
        />
        <source
          media="(min-width: 768px) and (max-width: 1024px)"
          srcset={imgProxy(
            props.fieldImage.uri.url,
            "size:1024:576/resizing_type:fill",
          )}
        />
        <source
          media="(min-width: 376px)"
          srcset={imgProxy(
            props.fieldImage.uri.url,
            "size:768:432/resizing_type:fill",
          )}
        />
        <source
          media="(max-width: 375px)"
          srcset={imgProxy(
            props.fieldImage.uri.url,
            "size:533:300/resizing_type:fill",
          )}
        />
        <img
          fetchpriority="high"
          src={imgProxy(
            props.fieldImage.uri.url,
            `size:533:300/resizing_type:fill`,
          )}
          alt={props.fieldImage.meta?.alt}
          height="300"
          width="375"
          loading="lazy"
        />
      </picture>
    </>
  );
}
