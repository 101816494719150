import type { SectionParagraph } from "~/types/drupal_jsonapi";
import { createMemo, Show } from "solid-js";
import type { AutopromosRendererProps } from "~/components/shared/Autopromos/AutopromosRenderer";
import {
  AutopromosRenderer,
  mayHaveShortcode,
} from "~/components/shared/Autopromos/AutopromosRenderer";

import "~/components/shared/WysiwygComponents.css";

export default function SectionParagraphComponent(
  props: {
    section: SectionParagraph;
    index: number;
  } & Pick<
    AutopromosRendererProps,
    "article" | "landing" | "wrapper" | "journey" | "landing"
  >,
) {
  const sectionId = createMemo(() => {
    return props.index ? "paragraph-" + props.index : undefined;
  });

  return (
    <>
      <section class="section" data-test="paragraph" id={sectionId()}>
        <Show when={props.section.field_title}>
          <h3 class="title">{props.section.field_title}</h3>
        </Show>
        <div class="text-part">
          <Show when={props.section.field_text?.value}>
            <Show
              when={mayHaveShortcode(props.section.field_text!.value)}
              fallback={<div innerHTML={props.section.field_text!.value} />}
            >
              <div>
                <AutopromosRenderer
                  text={props.section.field_text!.value}
                  fromFieldName="field_text"
                  article={props.article}
                  landing={props.landing}
                  wrapper={props.wrapper}
                  journey={props.journey}
                />
              </div>
            </Show>
          </Show>
        </div>
      </section>
    </>
  );
}
